<template>

	<container>

    <template v-if="!id">
      <p class="text-2xl text-center pt-5">কোন ব্যাচ এ ভর্তি হতে চাও?</p>
      <div class="p-4 grid grid-cols-1 md:grid-cols-2 border text-center my-10 md:my-16 gap-6" >
        <data-collection-form-item v-for="form in forms" :key="form.id" :form="form" :type="type"/>
      </div>
    </template>

    <router-view></router-view>

	</container>

</template>

<script>
import { mapGetters } from 'vuex';
import Container from "../../layouts/container";
import Btn from "../../../components/btn";
import DataCollectionFormItem from "./components/data-collection-form-item";

export default {
	name: "data-collections",
	components: {
    DataCollectionFormItem,
    Btn,
		Container,
	},
	data() {
    return {
      forms: []
    }
	},
	computed: {
    type(){
      return this.$route.params.type
    },
    id(){
      return this.$route.params.id
    },

  },
	created() {
    this.$store.dispatch( 'data_collections/get_forms' ).then( (forms) => {
      this.forms = Array.isArray( forms ) ? forms : [ ];
    });
  },
};
</script>

<style scoped>

</style>
