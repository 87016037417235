<template>
    <div class="border bg-white p-4 text-left">
      <div class="font-semibold text-base text-gray-600">
        {{ form.name}}
      </div>

      <component :is="collectable_component" :collectable="collectable" :show-price="true"></component>


      <div class="text-center">

        <div v-if="collectable_type.search(/Program/)>-1 && collectable_program_admitted">

          <btn class="my-3 md:my-10 mx-10" :router-path="`/my/courses/${collectable.id}/exams`" variant="success" >
            Exams
          </btn>

        </div>

        <btn v-else class="my-3 md:my-10 mx-10" :router-path="`/data-collections/${type}/${form.id}`" variant="info" >
          Admit Now
        </btn>

      </div>

    </div>
</template>

<script>
import Btn from "../../../../components/btn";
import MoneyAmount from "../../../../components/money-amount";
import ProgramCollectable from "./program-collectable";
import collectableMixins from "../mixins/collectable-mixins";
export default {
  name: "data-collection-form-item",
  components: {ProgramCollectable, MoneyAmount, Btn},
  props:['form','type'],
  mixins: [collectableMixins],
  computed: {
    collectable_program_admitted(){
      return ((this.form || {}).collectable || {}).admitted;
    }
  }

}
</script>

<style scoped>

</style>